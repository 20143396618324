<template>
  <span>
    <font-awesome-icon v-if="params.value" :icon="['far','eye']"/>
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'VisibleCellRenderer',
  data: function() {
  
  },
  created() {
  },
  methods: {

  }
})
</script>