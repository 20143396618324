var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title:
              _vm.data === null || !_vm.data.uuId
                ? _vm.$t("dataview.add")
                : _vm.$t("dataview.edit"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  [
                    !_vm.isReadOnly &&
                    ((_vm.data && _vm.data.uuId && _vm.canEdit()) ||
                      ((!_vm.data || !_vm.data.uuId) && _vm.canAdd()))
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              disabled:
                                _vm.entity.length === 0 ||
                                _vm.values.length === 0,
                              size: "sm",
                              variant: "success",
                            },
                            on: { click: _vm.ok },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        )
                      : _vm._e(),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.field.name"),
                "label-for": "name",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _vm.isReadOnly
                    ? _c("b-form-input", {
                        attrs: {
                          id: "filter-name",
                          type: "text",
                          readonly: "",
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      })
                    : _c("vue-bootstrap-typeahead", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }",
                          },
                        ],
                        ref: "typeahead",
                        staticClass: "w-100",
                        attrs: {
                          id: "filter-name",
                          type: "text",
                          data: _vm.names,
                          "data-vv-as": _vm.$t("dataview.field.name"),
                          "data-vv-name": "name",
                          maxlength: _vm.maxNameLength,
                          "data-vv-delay": "500",
                          state: _vm.fieldValidateUtil.stateValidate(
                            false,
                            _vm.veeFields,
                            _vm.errors,
                            "name"
                          ),
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.errors.first("name")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.field.parent_folder"),
                "label-for": "parent",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { id: "parent", type: "text", readonly: true },
                    model: {
                      value: _vm.folderName,
                      callback: function ($$v) {
                        _vm.folderName = $$v
                      },
                      expression: "folderName",
                    },
                  }),
                  !_vm.isReadOnly
                    ? _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeParentFolder()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.remove")))]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "info" },
                              on: {
                                click: function ($event) {
                                  return _vm.editParentFolder()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.select")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.field.description"),
                "label-for": "dataview-description",
              },
            },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "dataview-description",
                  placeholder: _vm.isReadOnly
                    ? ""
                    : _vm.$t("dataview.placeholder.description"),
                  "data-vv-as": _vm.$t("dataview.field.description"),
                  "data-vv-name": "description",
                  "data-vv-delay": "500",
                  "max-rows": 6,
                  rows: 3,
                  readonly: _vm.isReadOnly,
                },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  [
                    _c(
                      "b-form-group",
                      {
                        staticClass: "d-inline-block",
                        attrs: {
                          label: _vm.$t("dataview.field.sharing"),
                          "label-for": "dataview-visibility",
                        },
                      },
                      [
                        _c("b-form-radio-group", {
                          staticClass: "radio-group-class",
                          attrs: {
                            options: _vm.sharingOptions,
                            disabled: _vm.isReadOnly,
                          },
                          on: { change: _vm.visibilityChange },
                          model: {
                            value: _vm.sharedVisibility,
                            callback: function ($$v) {
                              _vm.sharedVisibility = $$v
                            },
                            expression: "sharedVisibility",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "sharing-members d-inline-block",
                        attrs: {
                          disabled: _vm.sharedVisibility === "public",
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.editSharingMembers()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.members")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("b-col", [
                _c(
                  "div",
                  [
                    _c("label", { staticClass: "d-block" }, [
                      _vm._v(
                        _vm._s(_vm.$t("dataview.field.editing_permissions"))
                      ),
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "permissions-members d-inline-block",
                        attrs: { size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.editPermissions()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.members")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t(`dataview.field.type`))),
              ]),
              !_vm.isReadOnly
                ? _c(
                    "button",
                    {
                      staticClass: "btn-action",
                      attrs: { id: "dataview_type" },
                      on: { click: _vm.typeSelectorToggle },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "plus"] },
                      }),
                      _c("b-popover", {
                        attrs: {
                          target: "dataview_type",
                          placement: "top",
                          triggers: "hover",
                          content: _vm.$t("dataview.button.type_add"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("BadgeGroup", {
                staticClass: "mb-3",
                attrs: { readOnly: _vm.isReadOnly },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ item, index }) {
                      return [
                        _c("Badge", {
                          key: index,
                          attrs: {
                            text: item,
                            variant: "white",
                            readOnly: _vm.isReadOnly,
                            pillable:
                              typeof item !== "undefined" && !!item.pillable,
                          },
                          on: {
                            badgeRemove: function ($event) {
                              return _vm.removeTypeField()
                            },
                            badgeClick: function ($event) {
                              return _vm.editTypeField()
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.entity,
                  callback: function ($$v) {
                    _vm.entity = $$v
                  },
                  expression: "entity",
                },
              }),
            ],
            1
          ),
          _c("hr", { staticClass: "solid" }),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.field.filter"),
                "label-for": "filter",
              },
            },
            [
              _c("FilterComponent", {
                attrs: {
                  disabled: _vm.isReadOnly || _vm.entity.length === 0,
                  id: "filter",
                  root: _vm.entity.length !== 0 ? _vm.entity[0] : null,
                  userId: _vm.userId,
                  schema: _vm.schema,
                  customFields: _vm.customFields,
                  predicate: _vm.predicate,
                  macros: _vm.macros,
                },
                model: {
                  value: _vm.query,
                  callback: function ($$v) {
                    _vm.query = $$v
                  },
                  expression: "query",
                },
              }),
            ],
            1
          ),
          _c("label", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.$t(`dataview.field.display`))),
          ]),
          !_vm.isReadOnly
            ? _c(
                "button",
                {
                  staticClass: "btn-action",
                  attrs: {
                    disabled: _vm.entity.length === 0,
                    id: "dataview_display_add",
                  },
                  on: { click: _vm.columnSelectorToggle },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } }),
                  _c("b-popover", {
                    attrs: {
                      target: "dataview_display_add",
                      placement: "top",
                      triggers: "hover",
                      content: _vm.$t("dataview.button.display_add"),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("BadgeGroup", {
            staticClass: "mb-3",
            attrs: { readOnly: _vm.isReadOnly },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item, index }) {
                  return [
                    _c("Badge", {
                      key: index,
                      attrs: {
                        text: _vm.valueText(item),
                        variant: "white",
                        readOnly: _vm.isReadOnly,
                        pillable: !!item.pillable,
                      },
                      on: {
                        badgeRemove: function ($event) {
                          return _vm.columnBadgeRemove(index)
                        },
                        badgeClick: function ($event) {
                          return _vm.columnBadgeClick(item)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.values,
              callback: function ($$v) {
                _vm.values = $$v
              },
              expression: "values",
            },
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("label", { staticClass: "mr-1" }, [
                    _vm._v(_vm._s(_vm.$t(`dataview.field.sortby`))),
                  ]),
                  !_vm.isReadOnly
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-action",
                          attrs: {
                            disabled: _vm.entity.length === 0,
                            id: "dataview_sort_add",
                          },
                          on: { click: _vm.sortSelectorToggle },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "plus"] },
                          }),
                          _c("b-popover", {
                            attrs: {
                              target: "dataview_sort_add",
                              placement: "top",
                              triggers: "hover",
                              content: _vm.$t("dataview.button.sort_add"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("BadgeGroup", {
                    staticClass: "mb-2",
                    attrs: { readOnly: _vm.isReadOnly },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item, index }) {
                          return [
                            _c("Badge", {
                              key: index,
                              attrs: {
                                text: _vm.sortText(item),
                                variant: "white",
                                readOnly: _vm.isReadOnly,
                                pillable: !!item.pillable,
                              },
                              on: {
                                badgeRemove: function ($event) {
                                  return _vm.removeSortField(index)
                                },
                                badgeClick: function ($event) {
                                  return _vm.editSortField(item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.sortfield,
                      callback: function ($$v) {
                        _vm.sortfield = $$v
                      },
                      expression: "sortfield",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("label", { staticClass: "mr-1 mb-1 mt-2" }, [
                    _vm._v(_vm._s(_vm.$t("dataview.aggregate"))),
                  ]),
                  _c("multiselect", {
                    staticClass: "custom-dropdown-options enable-option-icon",
                    attrs: {
                      "max-height": 300,
                      options: _vm.nominateOptions.map((i) => i.value),
                      "custom-label": _vm.getNominateOptionLabel,
                      placeholder: "",
                      searchable: false,
                      "allow-empty": false,
                      showLabels: false,
                      disabled: _vm.isReadOnly,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function (props) {
                          return [
                            _vm.nominate == props.option
                              ? _c("font-awesome-icon", {
                                  staticClass: "selected-option-icon",
                                  attrs: { icon: ["far", "check"] },
                                })
                              : _vm._e(),
                            _c("span", { staticClass: "option__title" }, [
                              _vm._v(
                                _vm._s(_vm.getNominateOptionLabel(props.option))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.nominate,
                      callback: function ($$v) {
                        _vm.nominate = $$v
                      },
                      expression: "nominate",
                    },
                  }),
                ],
                1
              ),
              _vm.nominate === "group-by"
                ? _c(
                    "b-col",
                    [
                      _c("label", { staticClass: "mr-1" }, [
                        _vm._v(_vm._s(_vm.$t(`dataview.field.groupby`))),
                      ]),
                      !_vm.isReadOnly
                        ? _c(
                            "button",
                            {
                              staticClass: "btn-action",
                              attrs: {
                                disabled: _vm.entity.length === 0,
                                id: "dataview_group_add",
                              },
                              on: { click: _vm.groupSelectorToggle },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "plus"] },
                              }),
                              _c("b-popover", {
                                attrs: {
                                  target: "dataview_group_add",
                                  placement: "top",
                                  triggers: "hover",
                                  content: _vm.$t("dataview.button.group_add"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("BadgeGroup", {
                        staticClass: "mb-2",
                        attrs: { readOnly: _vm.isReadOnly },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ item, index }) {
                                return [
                                  _c("Badge", {
                                    key: index,
                                    attrs: {
                                      text: _vm.groupText(item),
                                      variant: "white",
                                      readOnly: _vm.isReadOnly,
                                      pillable: !!item.pillable,
                                    },
                                    on: {
                                      badgeRemove: function ($event) {
                                        return _vm.removeGroupField(index)
                                      },
                                      badgeClick: function ($event) {
                                        return _vm.editGroupField(item)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2312925168
                        ),
                        model: {
                          value: _vm.groupfield,
                          callback: function ($$v) {
                            _vm.groupfield = $$v
                          },
                          expression: "groupfield",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "mt-2",
                      attrs: { disabled: _vm.isReadOnly, name: "dedup" },
                      model: {
                        value: _vm.dedup,
                        callback: function ($$v) {
                          _vm.dedup = $$v
                        },
                        expression: "dedup",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("dataview.remove_duplicates")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              staticClass: "mt-3",
              attrs: { label: _vm.$t("dataview.top_totals") },
            },
            [
              _c(
                "b-card",
                { staticClass: "checkbox-group" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "top_sum",
                              },
                              model: {
                                value: _vm.totals.top_sum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totals, "top_sum", $$v)
                                },
                                expression: "totals.top_sum",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("dataview.total")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "top_avg",
                              },
                              model: {
                                value: _vm.totals.top_avg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totals, "top_avg", $$v)
                                },
                                expression: "totals.top_avg",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("dataview.average")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "top_min",
                              },
                              model: {
                                value: _vm.totals.top_min,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totals, "top_min", $$v)
                                },
                                expression: "totals.top_min",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("dataview.min")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "top_max",
                              },
                              model: {
                                value: _vm.totals.top_max,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totals, "top_max", $$v)
                                },
                                expression: "totals.top_max",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("dataview.max")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("dataview.bottom_totals") } },
            [
              _c(
                "b-card",
                { staticClass: "checkbox-group" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "bottom_sum",
                              },
                              model: {
                                value: _vm.totals.bottom_sum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totals, "bottom_sum", $$v)
                                },
                                expression: "totals.bottom_sum",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("dataview.total")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "bottom_avg",
                              },
                              model: {
                                value: _vm.totals.bottom_avg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totals, "bottom_avg", $$v)
                                },
                                expression: "totals.bottom_avg",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("dataview.average")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "bottom_min",
                              },
                              model: {
                                value: _vm.totals.bottom_min,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totals, "bottom_min", $$v)
                                },
                                expression: "totals.bottom_min",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("dataview.min")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                disabled: _vm.isReadOnly,
                                name: "bottom_max",
                              },
                              model: {
                                value: _vm.totals.bottom_max,
                                callback: function ($$v) {
                                  _vm.$set(_vm.totals, "bottom_max", $$v)
                                },
                                expression: "totals.bottom_max",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("dataview.max")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isEntity("TASK") ||
          _vm.isEntity("STAFF") ||
          _vm.isEntity("DEPARTMENT") ||
          _vm.isEntity("LOCATION") ||
          _vm.isEntity("COMPANY") ||
          _vm.isEntity("SKILL")
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3",
                  attrs: {
                    label: _vm.$t("dataview.field.views"),
                    "label-for": "parent",
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "checkbox-group" },
                    [
                      _c(
                        "b-row",
                        [
                          _vm.isEntity("TASK")
                            ? _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        disabled: _vm.isReadOnly,
                                        name: "gantt",
                                      },
                                      model: {
                                        value: _vm.tabs.gantt,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tabs, "gantt", $$v)
                                        },
                                        expression: "tabs.gantt",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("dataview.gantt")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isEntity("TASK") ||
                          _vm.isEntity("STAFF") ||
                          _vm.isEntity("DEPARTMENT") ||
                          _vm.isEntity("LOCATION") ||
                          _vm.isEntity("COMPANY") ||
                          _vm.isEntity("SKILL")
                            ? _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        disabled: _vm.isReadOnly,
                                        name: "staff_usage",
                                      },
                                      model: {
                                        value: _vm.tabs.staff_usage,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tabs, "staff_usage", $$v)
                                        },
                                        expression: "tabs.staff_usage",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("dataview.staff_usage")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isEntity("TASK")
                            ? _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        disabled: _vm.isReadOnly,
                                        name: "board",
                                      },
                                      model: {
                                        value: _vm.tabs.board,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tabs, "board", $$v)
                                        },
                                        expression: "tabs.board",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("dataview.board")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("FieldSelectModal", {
        attrs: {
          show: _vm.showFieldSelect,
          nominate: _vm.nominate,
          root: _vm.entity.length !== 0 ? _vm.entity[0] : null,
          userId: _vm.userId,
          schema: _vm.schema,
          macros: _vm.macros,
          customFields: _vm.customFields,
          field: _vm.fieldedit,
          useName: true,
          fieldName: _vm.fieldeditName,
          agFunc: _vm.fieldeditAgFunc,
        },
        on: {
          "update:show": function ($event) {
            _vm.showFieldSelect = $event
          },
          success: _vm.fieldSelectOk,
        },
      }),
      _c("FieldSelectModal", {
        attrs: {
          show: _vm.showSortSelect,
          root: _vm.entity.length !== 0 ? _vm.entity[0] : null,
          userId: _vm.userId,
          useOrder: true,
          schema: _vm.schema,
          showEntities: !_vm.nominate,
          macros: _vm.macros,
          field: _vm.sortfieldedit,
          useAgFunc: false,
          agFunc: _vm.sorteditAgFunc,
          useCustomOrder: true,
        },
        on: {
          "update:show": function ($event) {
            _vm.showSortSelect = $event
          },
          success: _vm.sortSelectOk,
        },
      }),
      _c("FieldSelectModal", {
        attrs: {
          show: _vm.showGroupSelect,
          root: _vm.entity.length !== 0 ? _vm.entity[0] : null,
          userId: _vm.userId,
          schema: _vm.schema,
          showEntities: !_vm.nominate,
          macros: _vm.macros,
          field: _vm.groupfieldedit,
          useAgFunc: false,
          agFunc: _vm.sorteditAgFunc,
          useCustomOrder: true,
        },
        on: {
          "update:show": function ($event) {
            _vm.showGroupSelect = $event
          },
          success: _vm.groupSelectOk,
        },
      }),
      _c("FieldSelectModal", {
        attrs: {
          show: _vm.showTypeSelect,
          userId: _vm.userId,
          schema: _vm.schema,
          field: _vm.entity.length !== 0 ? _vm.entity[0] : null,
          useAgFunc: false,
          properties: false,
        },
        on: {
          "update:show": function ($event) {
            _vm.showTypeSelect = $event
          },
          success: _vm.typeSelectOk,
        },
      }),
      _c("MembersModal", {
        attrs: {
          readOnly: _vm.isReadOnly,
          show: _vm.showSharing,
          members: _vm.sharingMembers,
          title: _vm.$t("dataview.select_members_title"),
        },
        on: {
          "update:show": function ($event) {
            _vm.showSharing = $event
          },
          success: _vm.membersSelectOk,
        },
      }),
      _c("MembersModal", {
        attrs: {
          readOnly: _vm.isReadOnly,
          show: _vm.showPermissions,
          members: _vm.editingPermissions,
          title: _vm.$t("dataview.select_members_editing_title"),
        },
        on: {
          "update:show": function ($event) {
            _vm.showPermissions = $event
          },
          success: _vm.permissionsSelectOk,
        },
      }),
      _c("DataviewFolderSelectorModal", {
        attrs: {
          title: _vm.$t("dataview.select_folder"),
          show: _vm.dataviewFolderSelectorShow,
          preselected: _vm.folder,
          dataviewUuid: _vm.uuId,
          visibility: _vm.sharedVisibility,
          "ok-title": _vm.$t("button.select"),
        },
        on: {
          "update:show": function ($event) {
            _vm.dataviewFolderSelectorShow = $event
          },
          ok: _vm.dataviewFolderSelectOk,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.title_clear"),
            "ok-title": _vm.$t("button.confirm"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmClearFieldsOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptClearFields,
            callback: function ($$v) {
              _vm.promptClearFields = $$v
            },
            expression: "promptClearFields",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(" " + _vm._s(_vm.$t("dataview.confirmation.clear")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.title_aggregate"),
            "ok-title": _vm.$t("button.yes"),
            "cancel-title": _vm.$t("button.no"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmAggregateYes },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptAggregate,
            callback: function ($$v) {
              _vm.promptAggregate = $$v
            },
            expression: "promptAggregate",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("dataview.confirmation.turn_on_aggregate")) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }