<template>
  <div>
    <b-modal v-model="modalShow" size="lg" :title="data === null || !data.uuId ? $t('dataview.add') : $t('dataview.edit')" footer-class="footerClass"
      @hidden="modalCancel"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
      scrollable
    >
      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      
      <b-form-group :label="$t('dataview.field.name')" label-for="name">
        <b-input-group>
          <b-form-input v-if="isReadOnly" v-model="name" id="filter-name" type="text" readonly>
          </b-form-input>
          <vue-bootstrap-typeahead v-else id="filter-name" type="text" class="w-100"
            ref="typeahead"
            :data="names"
            :data-vv-as="$t('dataview.field.name')"
            data-vv-name="name"
            :maxlength="maxNameLength"
            data-vv-delay="500"
            v-model="name" 
            v-validate="{ required: true }"
            :state="fieldValidateUtil.stateValidate(false, veeFields, errors, 'name')">
          </vue-bootstrap-typeahead>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('name') }}
        </b-form-invalid-feedback>
      </b-form-group>
  
      <b-form-group :label="$t('dataview.field.parent_folder')" label-for="parent">
        <b-input-group>
          <b-form-input id="parent" type="text"
            v-model="folderName" 
            :readonly="true">
          </b-form-input>
          <b-input-group-append v-if="!isReadOnly">
            <b-button size="sm" @click="removeParentFolder()">{{ $t('button.remove') }}</b-button>
            <b-button size="sm" variant="info" @click="editParentFolder()">{{ $t('button.select') }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      
      <b-form-group :label="$t('dataview.field.description')" label-for="dataview-description">
        <b-form-textarea id="dataview-description" 
          :placeholder="isReadOnly? '' : $t('dataview.placeholder.description')"
          :data-vv-as="$t('dataview.field.description')"
          data-vv-name="description"
          data-vv-delay="500"
          v-model="description"
          :max-rows="6"
          :rows="3"
          :readonly="isReadOnly"
          />
      </b-form-group>

      <b-row>
        <b-col>
          <div>     
            <b-form-group class="d-inline-block" :label="$t('dataview.field.sharing')" label-for="dataview-visibility">     
              <b-form-radio-group
                class="radio-group-class"
                v-model="sharedVisibility"
                :options="sharingOptions"
                :disabled="isReadOnly"
                @change="visibilityChange">
              </b-form-radio-group>
            </b-form-group>
            <b-button :disabled="sharedVisibility === 'public'" class="sharing-members d-inline-block" size="sm" @click="editSharingMembers()">{{ $t('button.members') }}</b-button>
          </div>
        </b-col>
        <b-col>
          <div>     
            <label class="d-block">{{ $t('dataview.field.editing_permissions') }}</label>
            <b-button class="permissions-members d-inline-block" size="sm" @click="editPermissions()">{{ $t('button.members') }}</b-button>
          </div>
        </b-col>
      </b-row>
           
      <b-form-group>
      <label class="mr-1">{{ $t(`dataview.field.type`) }}</label>
      <button v-if="!isReadOnly" id="dataview_type" class="btn-action" @click="typeSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
        <b-popover
          target="dataview_type"
          placement="top"
          triggers="hover"
          :content="$t('dataview.button.type_add')">
        </b-popover>
      </button>
      <BadgeGroup v-model="entity" class="mb-3" :readOnly="isReadOnly">
        <template v-slot:default="{ item, index }">
          <Badge @badgeRemove="removeTypeField()" @badgeClick="editTypeField()"
            :text="item" 
            variant="white" 
            :readOnly="isReadOnly"
            :pillable="typeof item !== 'undefined' && !!item.pillable" :key="index" />
          </template>
      </BadgeGroup>
      </b-form-group>
      
      <hr class="solid">
      
      <b-form-group :label="$t('dataview.field.filter')" label-for="filter">
        <FilterComponent :disabled="isReadOnly || entity.length === 0" id="filter" :root="entity.length !== 0 ? entity[0] : null" :userId="userId" v-model="query" :schema="schema" :customFields="customFields" :predicate="predicate" :macros="macros"/>
      </b-form-group>
      
      <label class="mr-1">{{ $t(`dataview.field.display`) }}</label>
      <button v-if="!isReadOnly" :disabled="entity.length === 0" id="dataview_display_add" class="btn-action" @click="columnSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
        <b-popover
          target="dataview_display_add"
          placement="top"
          triggers="hover"
          :content="$t('dataview.button.display_add')">
        </b-popover>
      </button>
      <BadgeGroup v-model="values" class="mb-3" :readOnly="isReadOnly">
        <template v-slot:default="{ item, index }">
          <Badge @badgeRemove="columnBadgeRemove(index)" @badgeClick="columnBadgeClick(item)"
            :text="valueText(item)" 
            variant="white" 
            :readOnly="isReadOnly"
            :pillable="!!item.pillable" :key="index" />
          </template>
      </BadgeGroup>
    
      <b-row>
        <b-col>
          <label class="mr-1">{{ $t(`dataview.field.sortby`) }}</label>
          <button v-if="!isReadOnly" :disabled="entity.length === 0" id="dataview_sort_add" class="btn-action" @click="sortSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
            <b-popover
              target="dataview_sort_add"
              placement="top"
              triggers="hover"
              :content="$t('dataview.button.sort_add')">
            </b-popover>
          </button>
          <BadgeGroup v-model="sortfield" class="mb-2" :readOnly="isReadOnly">
            <template v-slot:default="{ item, index }">
              <Badge @badgeRemove="removeSortField(index)" @badgeClick="editSortField(item)"
                :text="sortText(item)" 
                variant="white"
                :readOnly="isReadOnly"
                :pillable="!!item.pillable" :key="index" />
              </template>
          </BadgeGroup>
        </b-col>
      </b-row>
        
      <b-row>
        <b-col>  
          <label class="mr-1 mb-1 mt-2">{{ $t('dataview.aggregate') }}</label>
          <multiselect v-model="nominate" class="custom-dropdown-options enable-option-icon"
            :max-height="300"
            :options="nominateOptions.map(i => i.value)"
            :custom-label="getNominateOptionLabel"
            :placeholder="''"
            :searchable="false" 
            :allow-empty="false"
            :showLabels="false"
            :disabled="isReadOnly">
            <template slot="option" slot-scope="props">
              <font-awesome-icon class="selected-option-icon" v-if="nominate == props.option" :icon="['far', 'check']" />
              <span class="option__title">{{ getNominateOptionLabel(props.option) }}</span>
            </template>
          </multiselect>
        </b-col>
        <b-col v-if="nominate === 'group-by'">
          <label class="mr-1">{{ $t(`dataview.field.groupby`) }}</label>
          <button v-if="!isReadOnly" :disabled="entity.length === 0" id="dataview_group_add" class="btn-action" @click="groupSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
            <b-popover
              target="dataview_group_add"
              placement="top"
              triggers="hover"
              :content="$t('dataview.button.group_add')">
            </b-popover>
          </button>
          <BadgeGroup v-model="groupfield" class="mb-2" :readOnly="isReadOnly">
            <template v-slot:default="{ item, index }">
              <Badge @badgeRemove="removeGroupField(index)" @badgeClick="editGroupField(item)"
                :text="groupText(item)" 
                variant="white"
                :readOnly="isReadOnly"
                :pillable="!!item.pillable" :key="index" />
              </template>
          </BadgeGroup>
        </b-col>
      </b-row>
    
      <b-row>
        <b-col>
          <b-form-checkbox :disabled="isReadOnly" v-model="dedup" name="dedup" class="mt-2">
            {{ $t('dataview.remove_duplicates') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      
      <b-form-group :label="$t('dataview.top_totals')" class="mt-3">
        <b-card class="checkbox-group">
          <b-row>
            <b-col>
              <b-form-checkbox :disabled="isReadOnly" v-model="totals.top_sum" name="top_sum">
                {{ $t('dataview.total') }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox :disabled="isReadOnly" v-model="totals.top_avg" name="top_avg">
                {{ $t('dataview.average') }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox :disabled="isReadOnly" v-model="totals.top_min" name="top_min">
                {{ $t('dataview.min') }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox :disabled="isReadOnly" v-model="totals.top_max" name="top_max">
                {{ $t('dataview.max') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </b-form-group>
      <b-form-group :label="$t('dataview.bottom_totals')">
        <b-card class="checkbox-group">
          <b-row>
            <b-col>
              <b-form-checkbox :disabled="isReadOnly" v-model="totals.bottom_sum" name="bottom_sum">
                {{ $t('dataview.total') }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox :disabled="isReadOnly" v-model="totals.bottom_avg" name="bottom_avg">
                {{ $t('dataview.average') }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox :disabled="isReadOnly" v-model="totals.bottom_min" name="bottom_min">
                {{ $t('dataview.min') }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox :disabled="isReadOnly" v-model="totals.bottom_max" name="bottom_max">
                {{ $t('dataview.max') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>    
      </b-form-group>
      
      <b-form-group v-if="(isEntity('TASK') || isEntity('STAFF') || isEntity('DEPARTMENT') || isEntity('LOCATION') || isEntity('COMPANY') || isEntity('SKILL'))" class="mt-3" :label="$t('dataview.field.views')" label-for="parent">
        <b-card class="checkbox-group">
          <b-row>
            <b-col v-if="isEntity('TASK')">
              <b-form-checkbox :disabled="isReadOnly" v-model="tabs.gantt" name="gantt">
                {{ $t('dataview.gantt') }}
              </b-form-checkbox>
            </b-col>
            <b-col v-if="(isEntity('TASK') || isEntity('STAFF') || isEntity('DEPARTMENT') || isEntity('LOCATION') || isEntity('COMPANY') || isEntity('SKILL'))">
              <b-form-checkbox :disabled="isReadOnly" v-model="tabs.staff_usage" name="staff_usage">
                {{ $t('dataview.staff_usage') }}
              </b-form-checkbox>
            </b-col>
            <!--<b-col v-if="(isEntity('TASK') || isEntity('STAFF') || isEntity('DEPARTMENT') || isEntity('LOCATION') || isEntity('COMPANY') || isEntity('SKILL') || isEntity('RESOURCE'))">
              <b-form-checkbox :disabled="isReadOnly" v-model="tabs.resource_usage" name="resource_usage">
                {{ $t('dataview.resource_usage') }}
              </b-form-checkbox>
            </b-col>-->
            <b-col v-if="isEntity('TASK')">
              <b-form-checkbox :disabled="isReadOnly" v-model="tabs.board" name="board">
                {{ $t('dataview.board') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </b-form-group>
            
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <!-- Allow saving edits if in edit mode. Allow saving new if in copy mode.-->
          <b-button v-if="!isReadOnly && ((data && data.uuId && canEdit()) || ((!data || !data.uuId) && canAdd()))" :disabled="entity.length === 0 || values.length === 0" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
    <FieldSelectModal :show.sync="showFieldSelect" :nominate="nominate" :root="entity.length !== 0 ? entity[0] : null" :userId="userId" :schema="schema" :macros="macros" :customFields="customFields" :field="fieldedit" :useName="true" :fieldName="fieldeditName" :agFunc="fieldeditAgFunc" @success="fieldSelectOk"/>
    <FieldSelectModal :show.sync="showSortSelect" :root="entity.length !== 0 ? entity[0] : null" :userId="userId" :useOrder="true" :schema="schema" :showEntities="!nominate" :macros="macros" :field="sortfieldedit" :useAgFunc="false" :agFunc="sorteditAgFunc" :useCustomOrder="true" @success="sortSelectOk"/>
    <FieldSelectModal :show.sync="showGroupSelect" :root="entity.length !== 0 ? entity[0] : null" :userId="userId" :schema="schema" :showEntities="!nominate" :macros="macros" :field="groupfieldedit" :useAgFunc="false" :agFunc="sorteditAgFunc" :useCustomOrder="true" @success="groupSelectOk"/>
    <FieldSelectModal :show.sync="showTypeSelect" :userId="userId" :schema="schema" :field="entity.length !== 0 ? entity[0] : null" :useAgFunc="false" :properties="false" @success="typeSelectOk"/>
    <MembersModal :readOnly="isReadOnly" :show.sync="showSharing" :members="sharingMembers" :title="$t('dataview.select_members_title')" @success="membersSelectOk"/>
    <MembersModal :readOnly="isReadOnly" :show.sync="showPermissions" :members="editingPermissions" :title="$t('dataview.select_members_editing_title')" @success="permissionsSelectOk"/>    
    
    <DataviewFolderSelectorModal :title="$t('dataview.select_folder')"
        :show.sync="dataviewFolderSelectorShow"
        :preselected="folder"
        :dataviewUuid="uuId"
        :visibility="sharedVisibility"
        :ok-title="$t('button.select')"
        @ok="dataviewFolderSelectOk"
        />
        
    <b-modal :title="$t('dataview.confirmation.title_clear')"
        v-model="promptClearFields"
        :ok-title="$t('button.confirm')"
        @ok="confirmClearFieldsOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('dataview.confirmation.clear') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <b-modal :title="$t('dataview.confirmation.title_aggregate')"
        v-model="promptAggregate"
        :ok-title="$t('button.yes')"
        :cancel-title="$t('button.no')"
        @ok="confirmAggregateYes"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('dataview.confirmation.turn_on_aggregate') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { cloneDeep } from 'lodash';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { dataviewProfileService, companyService, managementService } from '@/services';
import { EventBus } from '@/helpers';
import Multiselect from 'vue-multiselect';

export default {
  name: 'DataviewModal',
  components: {
    FilterComponent: () => import('@/components/Filter/FilterComponent'),
    VueBootstrapTypeahead,
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    FieldSelectModal:  () => import('@/components/modal/FieldSelectModal'),
    MembersModal:  () => import('@/components/modal/MembersModal'),
    DataviewFolderSelectorModal: () => import('@/components/modal/DataviewFolderSelectorModal'),
    Multiselect
  },
  props: {
    show:         { type: Boolean, required: true },
    userId:       { type: String, default: null },
    data:         { type: Object, default: null },
    isPublic:     { type: Boolean, default: false },
    folders:      { type: Object, default: () => { return {} } },
    folderUuid:       { type: String, default: null }
  },
  data() {
    return {
      permissionName: 'DATAVIEW',
      alertError: false,
      alertMsg: null,
      modalShow: false,
      uuId: null,
      name: null,
      description: null,
      names: [],
      maxNameLength: 255,
      schema: {},
      predicate: {},
      macros: {},
      customFields: {},
      query: null,
      entity: [],
      dedup: false,
      nominate: null,
      values: [],
      charts: null,
      fieldNames: {},
      sortfield: [],
      groupfield: [],
      folder: null,
      folderName: null,
      sorteditAgFunc: null,
      fieldedit: null,
      sortfieldedit: null,
      groupfieldedit: null,
      fieldeditName: null,
      fieldeditAgFunc: null,
      showGroupSelect: false,
      showSortSelect: false,
      showFieldSelect: false,
      showTypeSelect: false,
      
      sharedVisibility: 'private',

      showSharing: false,
      sharingMembers: "",
      showPermissions: false,
      editingPermissions: "",
      
      promptClearFields: false,
      pendingType: null,
      promptAggregate: false,
      dataviewFolderSelectorShow: false,
      tabs: {
        gantt: true,
        staff_usage: true,
        board: true
      },
      totals: {
        top_sum: false,
        top_min: false,
        top_max: false,
        top_avg: false,
        bottom_sum: false,
        bottom_min: false,
        bottom_max: false,
        bottom_avg: false
      },
      nominateOptions: [
        { text: 'Filter first, then group results', value: 'nominate'},
        { text: 'Group first, then filter results', value: 'group'},
        { text: 'Group by property', value: 'group-by'},
        { text: 'Filter only', value: 'left-join'}
      ],
      isReadOnly: false
    }
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
    for (var i = 0; i < this.nominateOptions.length; i++) {
      this.nominateOptions[i].text = this.$t(`dataview.nominate_option${i+1}`);
    }
  },
  mounted() {
     this.modalShow = this.show;
     if (this.show) {
      this.isReadOnly = !this.canEdit('DATAVIEW')
      this.resetValues();
     }
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.$validator.resume();
        this.resetValues();
        for (var i = 0; i < this.nominateOptions.length; i++) {
          this.nominateOptions[i].text = this.$t(`dataview.nominate_option${i+1}`);
        }
        this.alertMsg = null;
        this.modalShow = newValue;
        this.isReadOnly = false;
        if (newValue) {
          this.isReadOnly = !this.canEdit('DATAVIEW');
        }
      }
    }
  },
  computed: {
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'name');
    },
    showError() {
      return this.alertMsg != null;
    },
    sharingOptions() {
      return [{text: this.$t('dataview.public'), value: 'public'},
                       {text: this.$t('dataview.private'), value: 'private'}]
    }
  },
  methods: {
    valueText(item) {
      if (typeof item === 'object') {
        return `${item.agFunction.toUpperCase()}(${item.field})`;
      }
      return item.replace(/\(A\)|\(A,B\)|\(A,B,C\)/, "()");
    },
    sortText(item) {
      if (typeof item === 'object') {
        if (typeof item.agFunction !== 'undefined' &&
            item.agFunction.agFunction !== null) {
          return `${item.agFunction.agFunction.toUpperCase()}(${item.field})`;
        }
        else {
          return item.field.replace(/\(A\)|\(A,B\)|\(A,B,C\)/, "()");
        }
      }
      return item;
    },
    groupText(item) {
      return item;
    },
    async resetValues() {
      
      this.schema = await managementService.info({ type: 'model', opts: 'xbrief' }).then((response) => { 
        return response.data.propertyList;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
      this.predicate = await this.$store.dispatch('data/info', { type: 'predicate' }).then((value) => { 
        return value;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
      this.macros = await this.$store.dispatch('data/info', { type: 'macro' }).then((value) => { 
        return value;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
      this.customFields = await managementService.info({type: 'field', opts: 'all'})
      .then(response => {
        return response.data.propertyList;
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          return null
        }
        if (e.response.status !== 404) {
          this.httpAjaxError(e)
        }
        
        return null
      });
      
      // add the frontend custom macros
      this.macros['=active()'] = { arguments: [ {type: 'none'} ], type: 'Boolean' };
      this.macros['=started()'] = { arguments: [ {type: 'none'} ], type: 'Boolean' };
      this.macros['=ended()'] = { arguments: [ {type: 'none'} ], type: 'Boolean' };
      
      this.setValues();
    },
    filterGroup(group) {
      const items = group.items.filter(i => i.name.toLowerCase().includes(this.morefilter.toLowerCase()));
      return this.morefilter === '' || group.name.toLowerCase().includes(this.morefilter.toLowerCase()) || items.length !== 0;
    },
    prepareQuery(query, edit) {
      if (query === null) {
        return query;
      }
      
      if (query !== null) {
        if (query.type === 'group') {
          for (var i = 0; i < query.children.length; i++) {
            const child = query.children[i];
            query.children[i] = this.prepareQuery(child, edit);
          }
        }
        else {
          // clear the errors before checking
          delete query.operatorError;
          delete query.fieldError;
          delete query.valueError;
          delete query.regexError;
          // Using value in v-modal causes problems getting the values
          // back from the edtiors so changing this to value1 works better
          if (edit) {
            this.$set(query, 'value1', query.value);
          }
          else {
            this.$set(query, 'value', typeof query.value1 !== 'undefined' ? query.value1 : query.value);
            delete query.value1;
          }
        }
      }
      return query;
    },
    validateQuery(query) {
      var result = true;
      if (query === null) {
        return result;
      }
      
      if (query.type === 'group' &&
          query.operator === '_not_' &&
          query.children.length > 1) {
        result = false;
        this.alertMsg = this.$t('dataview.error.not');    
        this.alertError = true;
      }
      
      if (query !== null) {
        if (query.type === 'group') {
          for (var child of query.children) {
            result = result && this.validateQuery(child);
          }
        }
        else {
          // clear the errors before checking
          delete query.operatorError;
          delete query.fieldError;
          delete query.valueError;
          delete query.regexError;
          
          // check for errors
          if (query.field === '') {
            result = false;
            this.$set(query, 'fieldError', true);
          }
          else if (query.operator === '') {
            result = false;
            this.$set(query, 'operatorError', true);
          }
          else if (query.operator === 'regex' ||
                   query.operator === 'containsRegex') {
            if (!query.value) {
              result = false;
              this.$set(query, 'valueError', true);  
              return result;
            }
            
            try {
                var patt = new RegExp(query.value);
            
              patt.test('123456789');
            
            }
            catch(err) {
              result = false;
              this.$set(query, 'regexError', err.message);
            }        
          }
          else if ((query.fieldtype === 'Integer' ||
                    query.fieldtype === 'Boolean' ||
                    query.fieldtype === 'Enum' ||
                    query.fieldtype === 'Date') && query.value === '') {
            result = false;
            this.$set(query, 'valueError', true);
          }
          else if ((query.operator === 'has' ||
                    query.operator.startsWith('contains') ||
                    query.operator.startsWith('!contains') ||
                    query.operator === 'ending' ||
                    query.operator === '!ending' ||
                    query.operator === 'without' ||
                    query.operator === '!regex' ||
                    query.operator === '!fuzzy' ||
                    query.operator === '!prefix' ||
                    query.operator === 'fuzzy' ||
                    query.operator === 'prefix' ||
                    query.operator === 'within') &&
                   !query.value) {
            result = false;
            this.$set(query, 'valueError', true);        
          }
        }
      }
      return result;
    },
    validateSharing() {
      if (this.editingPermissions === "") {
        this.alertMsg = this.$t('dataview.error.members_editing_empty');
        this.alertError = true;
        return false;
      }
      return true;
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.alertError = false;
      
      if (!this.validateSharing()) {
        return;
      }
      
      // prepare the query for submit
      const query = this.prepareQuery(cloneDeep(this.query), false);
      
      // check query
      if (!this.validateQuery(this.prepareQuery(this.query, false))) {
        return;
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.filterSubmit(cloneDeep(query));
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
        }
      });
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    async filterSubmit(query) {
      if (this.sharedVisibility !== 'private') {  
            this.sharingMembers = this.userId;
      }
      
      // update charts with changes
      if (typeof this.charts !== 'undefined' &&
          this.charts !== null) {
        for (var chart of this.charts) {
          chart.entity = this.entity[0];
          chart.dedup = this.deup;
          chart.nominate = this.nominate;
          chart.values = this.values;
          chart.filter = query;
        }
      }
            
      const filter = {
                      name: this.name, 
                      description: this.description, 
                      values: this.values, 
                      fieldNames: this.fieldNames, 
                      sortfield: this.sortfield, 
                      groupfield: this.groupfield.length > 0 ? this.groupfield[0] : null,
                      sortdirection: this.sortdirection,
                      entity: this.entity.length !== 0 ? this.entity[0] : null, 
                      dedup: this.dedup, 
                      nominate: this.nominate,
                      query: query,
                      sharedVisibility: this.sharedVisibility,
                      sharingMembers: this.sharingMembers,
                      editingPermissions: this.editingPermissions,
                      charts: this.charts,
                      parent: this.folder,
                      showGantt: this.tabs.gantt,
                      showStaff: this.tabs.staff_usage,
                      showResource: this.tabs.resource_usage,
                      showBoard: this.tabs.board,
                      topSum: this.totals.top_sum,
                      topMin: this.totals.top_min,
                      topMax: this.totals.top_max,
                      topAvg: this.totals.top_avg,
                      bottomSum: this.totals.bottom_sum,
                      bottomMin: this.totals.bottom_min,
                      bottomMax: this.totals.bottom_max,
                      bottomAvg: this.totals.bottom_avg,
                      settings: this.data && this.data.settings ? this.data.settings : null
                    };
      
      var data = null;
                    
      if (this.uuId === null) {
        data = await this.createDataviewProfile(filter);
        if (data !== null) {
          this.uuId = data.uuId;
        }
      }
      else {
        filter.uuId = this.uuId;
        data = await this.updateDataviewProfile(filter);
      }
      
      await this.updateSharedVisibility();
      
      const existingIds = this.data !== null &&
                          typeof this.data.sharingMembers !== 'undefined' &&
                          this.data.sharingMembers !== ""
                           ? this.data.sharingMembers.split(',') : [];
      const sharedIds = this.sharingMembers !== "" ? this.sharingMembers.split(',') : [];
      // add share
      for (var shareToId of sharedIds) {
        const exists = existingIds.filter(e => e === shareToId);
        if (exists.length === 0 && shareToId !== this.userId) {
          await this.shareDataviewProfile(shareToId);
        }
      }
      
      // remove unshared
      for (var existingId of existingIds) {
        const exists = sharedIds.filter(e => e === existingId);
        if (exists.length === 0) {
          await this.unshareDataviewProfile(existingId);
        }
      }      
    
      const userId = this.$store.state.authentication.user.uuId;
      this.$store.dispatch('data/clearDataViewProfileCache', userId, localStorage.companyId);
 
      this.$emit('update:show', false);
      this.$emit('success', { data: data, uuId: this.uuId,
                              name: {
                                changed: this.data !== null && this.data.name !== this.name,
                                text: this.name      
                              }, 
                              visibility: { 
                                changed: this.data === null || this.data.sharedVisibility !== this.sharedVisibility, value: this.sharedVisibility 
                              },
                              folder: {
                                changed: this.data !== null && this.data.parent !== this.folder
                              }
                             });
    },   
    async updateSharedVisibility() {
      if (this.data === null || this.data.sharedVisibility !== this.sharedVisibility) {
        const company = await companyService.list({limit: -1, start: 0}).then((response) => {
          const data = response.data;
          return data.filter(d => d.type === 'Primary');
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return null;
        });
        
        if (company.length > 0) {
          if (this.sharedVisibility === 'private') {
            if (this.data !== null) {
              await this.unshareDataviewProfile(company[0].uuId, true);
            }
          }
          else {
            await this.shareDataviewProfile(company[0].uuId, true);
          }
        }
      }
    }, 
    async createDataviewProfile(dataview) {
      const data = await dataviewProfileService.create([dataview],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data[0];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return null;
      });
      return data;
    },
    async updateDataviewProfile(dataview) {
      const data = await dataviewProfileService.update([dataview],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data[0];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return null;
      });
      return data;
    },
    async shareDataviewProfile(shareToId, isPublic = false) {
        await dataviewProfileService.share(this.uuId, shareToId, isPublic).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    async unshareDataviewProfile(shareToId, isPublic = false) {
        await dataviewProfileService.unshare(this.uuId, shareToId, isPublic).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    setValues() {
      this.columns = this.allcolumns;
      if (this.data !== null) {
        const dataCopy = cloneDeep(this.data);
        this.uuId = dataCopy.uuId ? dataCopy.uuId : null;
        this.$nextTick(() => {
          if (typeof this.$refs.typeahead !== 'undefined') {
            this.$refs.typeahead.inputValue = this.name = cloneDeep(this.data.name);
          } else if (this.isReadOnly) {
            this.name = cloneDeep(this.data.name);
          }
        });
        this.description = dataCopy.description;
        this.query = this.prepareQuery(dataCopy.query, true);
        this.values = dataCopy.values;
        this.fieldNames = dataCopy.fieldNames ? dataCopy.fieldNames : {};
        this.entity = dataCopy.entity !== null ? [dataCopy.entity] : [];
        this.dedup = dataCopy.dedup;
        this.nominate = typeof dataCopy.nominate === 'string' ? dataCopy.nominate : dataCopy.nominate ? 'group' : 'left-join';
        if (dataCopy.sortfield !== null && typeof dataCopy.sortAgFunc !== 'undefined' &&
            dataCopy.sortAgFunc.agFunction !== null) {
          this.sortfield = [{ field: dataCopy.sortfield, agFunction: dataCopy.sortAgFunc }];
        }
        else {
          this.sortfield = dataCopy.sortfield !== null ? dataCopy.sortfield : [];
          if (typeof this.sortfield === 'string') {
            this.sortfield = [this.sortfield];
          }
        }
        this.groupfield = dataCopy.groupfield ? [dataCopy.groupfield] : [];
        this.sortdirection = typeof dataCopy.sortdirection !== 'undefined' ? dataCopy.sortdirection : 'incr';
        this.sharingMembers = typeof dataCopy.sharingMembers !== 'undefined' ? dataCopy.sharingMembers : this.userId;
        this.editingPermissions = typeof dataCopy.editingPermissions !== 'undefined' ? dataCopy.editingPermissions : this.userId;
        this.sharedVisibility = typeof dataCopy.sharedVisibility !== 'undefined' ? dataCopy.sharedVisibility : this.isPublic ? 'public' : 'private';
        this.charts = dataCopy.charts;
        this.folder = dataCopy.parent;
        this.folderName = this.folders[this.folder];
        this.tabs.gantt = typeof dataCopy.showGantt !== 'undefined' ? dataCopy.showGantt : true;
        this.tabs.staff_usage = typeof dataCopy.showStaff !== 'undefined' ? dataCopy.showStaff : true;
        this.tabs.resource_usage = typeof dataCopy.showResource !== 'undefined' ? dataCopy.showResource : true;
        this.tabs.board = typeof dataCopy.showBoard !== 'undefined' ? dataCopy.showBoard : true;
        this.totals.top_sum = typeof dataCopy.topSum !== 'undefined' ? dataCopy.topSum : false;
        this.totals.top_min = typeof dataCopy.topMin !== 'undefined' ? dataCopy.topMin : false;
        this.totals.top_max = typeof dataCopy.topMax !== 'undefined' ? dataCopy.topMax : false;
        this.totals.top_avg = typeof dataCopy.topAvg !== 'undefined' ? dataCopy.topAvg : false;
        this.totals.bottom_sum = typeof dataCopy.bottomSum !== 'undefined' ? dataCopy.bottomSum : false;
        this.totals.bottom_min = typeof dataCopy.bottomMin !== 'undefined' ? dataCopy.bottomMin : false;
        this.totals.bottom_max = typeof dataCopy.bottomMax !== 'undefined' ? dataCopy.bottomMax : false;
        this.totals.bottom_avg = typeof dataCopy.bottomAvg !== 'undefined' ? dataCopy.bottomAvg : false;
      }
      else {
        this.uuId = null;
        this.name = "";
        this.query = null;
        this.values = [];
        this.dedup = false;
        this.nominate = 'nominate';
        this.entity = [];
        this.sortfield = [];
        this.sortdirection = 'incr';
        this.sharingMembers = this.userId;
        this.editingPermissions = this.userId;
        this.sharedVisibility = this.isPublic ? 'public' : 'private';
        this.charts = null;
        this.folder = this.folderUuid;
        if (this.folderUuid !== null) {
          this.folderName = this.folders[this.folder];
        }
        else {
          this.folderName = null;
        }
        this.tabs.gantt = true;
        this.tabs.staff_usage = true;
        this.tabs.resource_usage = true;
        this.tabs.board = true;
        this.totals.top_sum = false;
        this.totals.top_min = false;
        this.totals.top_max = false;
        this.totals.top_avg = false;
        this.totals.bottom_sum = false;
        this.totals.bottom_min = false;
        this.totals.bottom_max = false;
        this.totals.bottom_avg = false;
      }
    },
    dismissAlert() {
      this.alertMsg = null;
      this.alertError = false;
    },
    columnBadgeRemove: function(index) {
      const value = this.values[index];
      delete this.fieldNames[value];
      this.values.splice(index,1);
    },
    columnBadgeClick(value) {
      if (typeof value === 'object') {
        this.fieldedit = value.field;
        this.fieldeditName = value.name;
        this.fieldeditAgFunc = { applyFunction: true, agFunction: value.agFunction };
      }
      else {
        this.fieldedit = value;
        this.fieldeditName = this.fieldNames ? this.fieldNames[value] : null;
        this.fieldeditAgFunc = { applyFunction: false, agFunction: null };      
      }
      this.showFieldSelect = true;
    },
    columnSelectorToggle() {
      this.fieldedit = null;
      this.showFieldSelect = true;
    },
    sortSelectorToggle() {
      this.sortfieldedit = null;
      this.showSortSelect = true;
    },
    editSortField(value) {
      this.sortfieldedit = value;
      this.showSortSelect = true;
    },
    removeSortField(index) {
      this.sortfield.splice(index,1);
    },
    groupSelectorToggle() {
      this.groupfieldedit = null;
      this.showGroupSelect = true;
    },
    editGroupField(value) {
      this.groupfieldedit = value;
      this.showGroupSelect = true;
    },
    removeGroupField(index) {
      this.groupfield.splice(index,1);
    },
    typeSelectorToggle() {
      this.showTypeSelect = true;
    },
    editTypeField() {
      this.showTypeSelect = true;
    },
    removeTypeField() {
      if (this.entity.length !== 0) {
        this.promptClearFields = true;
        this.pendingType = [];
        return;   
      }
      this.entity = [];
    },
    clearColumns() {
      this.values = [];
    },
    fieldSelectOk(field) {
      const orig = field.original;
      const origAgFunc = field.originalAgFunc;
      const fieldName = field.name;
      const value = field.agFunc.applyFunction ? { field: field.result, agFunction: field.agFunc.agFunction, name: fieldName } : field.result;
      const exists = this.values.filter(v => (typeof v === 'string' && typeof value === 'string' && v === value) || 
                                             (typeof v === 'object' && typeof value === 'object' && v.name === value.name && v.agFunction === value.agFunction));
      if (exists.length !== 0 && 
          ((typeof value === 'string' && field.result !== field.original) ||
          (typeof value === 'object' && field.result !== field.original && field.originalAgFunc !== null && field.agFunc.agFunction !== field.originalAgFunc.agFunction))) {
        this.alertMsg = this.$t('dataview.error.columnexists', [fieldName]);
        this.alertError = true;
        return false;
      }
      
      const index = this.values.findIndex(function(val) {
        return (typeof val === 'string' && val === orig && origAgFunc.agFunction === null) ||
               (typeof val === 'object' && val.field === orig && val.agFunction === origAgFunc.agFunction);
      });
      
      if (index !== -1) {
        this.values.splice(index, 1, value);
        delete this.fieldNames[orig];
      }
      else {
        this.values.push(value);
      }
      
      if (typeof value === 'string') {
        this.fieldNames[value] = fieldName;
      }
      
      // prompt to turn on aggregate
      if (!(this.nominate === 'group' ||
            this.nominate === 'group-by')) {
        for (var val of this.values) {
          if (typeof val === 'object' &&
              (val.agFunction === 'count' ||
               val.agFunction === 'sum' ||
               val.agFunction === 'min' ||
               val.agFunction === 'max' ||
               val.agFunction === 'mean')) {
              this.promptAggregate = true;   
           }
        }
      }
    },
    sortSelectOk(field) {
      const orig = field.original;
      const index = this.sortfield.findIndex(function(val) {
        return (typeof val === 'string' && val === field.result) ||
               (typeof val === 'object' && ((val.field === field.result && val.agFunction === field.agFunc && field.sortdirection === val.sortdirection) ||
               (orig !== null && val.field === orig.field && val.sortdirection === orig.sortdirection)));
      });
      
      if (index !== -1) {
        this.sortfield.splice(index, 1, { field: field.result, sortdirection: field.sortdirection });
      }
      else {
        this.sortfield.push({ field: field.result, sortdirection: field.sortdirection });
      }
    },
    groupSelectOk(field) {
      this.groupfield.splice(0, 1);
      this.groupfield.push(field.result);
    },
    typeSelectOk(field) {
      if (this.entity.length !== 0 &&
          this.entity[0] !== field.result) {
        this.promptClearFields = true;
        this.pendingType = field.result;
        return;   
      }
      this.entity = [field.result];
    },
    confirmClearFieldsOk() {
      if (typeof this.pendingType === 'string') {
        this.entity = [this.pendingType];
      }
      else {
        this.entity = [];
      }
      this.query = null;
      this.values = [];
      this.sortfield = [];
      this.groupfield = [];
      this.charts = [];
    },
    confirmAggregateYes() {
      this.nominate = 'group';
    },
    editSharingMembers() {
      this.showSharing = true;
    },
    editPermissions() {
      this.showPermissions = true;
    },
    membersSelectOk(members) {
      if (members === "") {
        this.alertMsg = this.$t('dataview.error.members_empty');
        this.alertError = true;
        return;
      }
      this.sharingMembers = members;
      
      const permissionArray = this.editingPermissions.split(',');
      for (var permission of permissionArray) {
        if (!this.sharingMembers.includes(permission)) {
          const permissions = this.editingPermissions.split(',');
          const index = permissions.indexOf(permission);
          permissions.splice(index, 1);
          this.editingPermissions = permissions.join(',');  
        }
      }
    },
    permissionsSelectOk(permissions) {
      if (permissions === "") {
        this.alertMsg = this.$t('dataview.error.permissions_empty');
        this.alertError = true;
        return;
      }
      this.editingPermissions = permissions;
      const permissionArray = permissions.split(',');
      for (var permission of permissionArray) {
        if (permission !== this.userId &&
            !this.sharingMembers.includes(permission)) {
          this.sharingMembers === "" ? this.sharingMembers = permission : this.sharingMembers += `,${permission}`;   
        }
      }
    },
    editParentFolder() {
      this.dataviewFolderSelectorShow = true;
    },
    dataviewFolderSelectOk(details) {
      this.folder = details.uuId;
      this.folderName = details.name;
    },
    visibilityChange() {
      this.folder = null;
      this.folderName = null;
    },
    removeParentFolder() {
      this.folder = null;
      this.folderName = null;
    },
    isEntity(value) {
      if (this.entity.length !== 0) {
        const entity = this.entity[this.entity.length - 1];
        if (entity === value) {
          return true;    
        }

      }
      return false;
    },
    getNominateOptionLabel(value) {
      return this.nominateOptions.find(i => i.value === value)?.text || value;
    },
  }
}
</script>
<style>
.sharing-members {
  margin-top: 5px;
}

.card.checkbox-group {
  box-shadow: none;
  margin-bottom: 0;
}
</style>
